// @flow strict
import React from 'react';
import styles from './Content.module.scss';
import Tags from '../Tags';

type Props = {
  body: string,
  title: string
};

const Content = ({ body, title, tags = { tags: null, tagSlugs: null } }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>
    {tags.tags && tags.tagSlugs && <Tags tags={tags.tags} tagSlugs={tags.tagSlugs} />}
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
