// @flow strict
import React from 'react';
import moment from 'moment';
import styles from './Meta.module.scss';

type Props = {
  date: string
};

/**
 * TODO: decouple update/create date bellow.
 * 1. Add updated at in every post
 * 2. Display only update date in blog itself
 * 3. Continue sorting by creation date though
 */
const Meta = ({ date }: Props) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}>Updated {moment(date).format('D MMM YYYY')}</p>
  </div>
);

export default Meta;
